@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --almost-black: rgb(0, 9, 12);
  --almost-white: #dcdfea;
  --light-grey: #959aa7;
  --middle-grey: #414349;
  --dark-grey: #1d1d1d;
  --primary-color: var(--almost-white);
  --secondary-color: var(--light-grey);
  --background-color: var(--almost-black);

  --margin: 1.8rem;
}

@media only screen and (max-width: 600px) {
  :root {
    --margin: 1.4rem;
  }
}

body {
  margin: 0;
  background-color: var(--background-color);
  color: var(--primary-color);
  font-weight: 100;
  cursor: default;
  transition: none;
}

* {
  transition: background-color 1s ease-out;
}

.secondary-color {
  color: var(--secondary-color);
}

#frame {
  display: flex;
  flex-direction: column;
  border: 1px solid var(--primary-color);
  height: calc(100% - (var(--margin) * 2));
  width: calc(100vw - (var(--margin) * 2));
  margin: var(--margin);
  position: fixed;
  font-weight: 100;
  overflow: hidden;
}

nav {
  position: relative;
  top: var(--margin);
  left: var(--margin);
  z-index: 100;
  width: fit-content;
}

nav > ul {
  margin-top: calc(var(--margin) * 2);
}

nav > ul > li {
  margin-top: calc(var(--margin) / 2);
  transition: all 0.2s;
  color: var(--secondary-color);
}

nav > ul > li:hover:not(.selected) {
  color: var(--primary-color);
  padding-left: 10px;
  position: relative;
}

nav > ul > li.selected {
  color: var(--primary-color);
}

.badge {
  border-color: var(--secondary-color);
}

.badge-selected {
  border-color: var(--primary-color);
}

.circle {
  width: 8px;
  height: 8px;
  border-radius: 100px;
}

#intro {
  position: absolute;
  bottom: var(--margin);
  right: var(--margin);
  width: min(20rem, calc(100%) - calc(var(--margin) * 2));
}

#about {
  position: relative;
  left: var(--margin);
  top: calc(var(--margin) * 1);
  width: calc(100% - (var(--margin) * 2));
  overflow: auto;
  height: auto;
  scrollbar-width: none;
  -ms-overflow-style: none;
  height: 100%;
  margin-bottom: var(--margin);
  -webkit-mask-image: linear-gradient(
    to bottom,
    transparent,
    rgba(0, 0, 0, 1) 8%,
    rgba(0, 0, 0, 1) 75%,
    transparent
  );
  mask-image: linear-gradient(
    to bottom,
    transparent,
    rgba(0, 0, 0, 1) 8%,
    rgba(0, 0, 0, 1) 75%,
    transparent
  );
}

#background {
  padding-top: calc(var(--margin) * 2);
}

@media only screen and (min-width: 1023px) {
  #about {
    top: calc(var(--margin) * -2.3);
    height: calc(100% - (var(--margin) * 4.75));
    overflow: visible;
    -webkit-mask-image: linear-gradient(
      to bottom,
      rgba(0, 0, 0, 1) 0%,
      rgba(0, 0, 0, 1) 80%,
      transparent
    );
    mask-image: linear-gradient(
      to bottom,
      rgba(0, 0, 0, 1) 0%,
      rgba(0, 0, 0, 1) 80%,
      transparent
    );
  }
  #background {
    padding-top: calc(var(--margin) * 5.5);
  }
}

#background-container {
  width: min(calc(var(--margin) * 14.2), 100%);
}

#background-container p {
  margin-bottom: 1rem;
}

#skills {
  overflow: visible;
  margin-top: 0;
  position: relative;
  transition: all 1s;
  opacity: 0;
}

#skills.fadein {
  opacity: 1;
}

#skills-container {
  padding-bottom: 5rem;
}

@media only screen and (min-width: 1023px) {
  #skills {
    height: calc(100vh - (var(--margin) * 6.6));
  }

  #background-container {
    overflow: auto;
    width: min(calc(var(--margin) * 20), 100%);
    height: 100%;
    padding-bottom: 8rem;
  }

  #skills-container {
    overflow: auto;
    width: min(calc(var(--margin) * 20), 100%);
    height: 100%;
    padding-bottom: 6rem;
  }
}

#about::-webkit-scrollbar,
#skills-container::-webkit-scrollbar,
#background-container::-webkit-scrollbar,
#projects::-webkit-scrollbar {
  display: none;
}

.gradient-opacity {
  -webkit-mask-image: linear-gradient(
    to bottom,
    transparent,
    rgba(0, 0, 0, 1) 8.5%,
    rgba(0, 0, 0, 1) 80%,
    transparent
  );
  mask-image: linear-gradient(
    to bottom,
    transparent,
    rgba(0, 0, 0, 1) 8.5%,
    rgba(0, 0, 0, 1) 80%,
    transparent
  );
}

.gradient-opacity-bottom {
  -webkit-mask-image: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 1) 0%,
    rgba(0, 0, 0, 1) 80%,
    transparent
  );
  mask-image: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 1) 0%,
    rgba(0, 0, 0, 1) 80%,
    transparent
  );
}

#language {
  display: flex;
  position: fixed;
  gap: 1rem;
  right: var(--margin);
  top: 0;
  height: var(--margin);
  align-items: center;
}

#language a {
  transition: all 0.2s;
}

#credits {
  display: flex;
  position: fixed;
  gap: 1rem;
  left: calc(var(--margin) + 10px);
  bottom: 0;
  height: var(--margin);
  align-items: center;
  color: var(--secondary-color);
}

#credits span {
  margin-inline: -10px;
}

#email {
  color: var(--primary-color);
}

#theme {
  display: flex;
  position: fixed;
  gap: 1rem;
  left: 0;
  bottom: 0;
  height: var(--margin);
  align-items: center;
  rotate: -90deg;
  transform: translate(65%, -95%);
  user-select: none;
}

@media only screen and (max-width: 600px) {
  #theme {
    transform: translate(62%, -150%);
  }
}

#contact {
  position: fixed;
  right: var(--margin);
  bottom: 0;
  height: var(--margin);
  color: var(--primary-color);
  display: flex;
  align-items: center;
}

#projects {
  position: absolute;
  right: var(--margin);
  height: 100%;
  overflow: scroll;
  scrollbar-width: none;
  -ms-overflow-style: none;
  pointer-events: none;
}

#main {
  position: absolute;
  height: 100%;
  width: 100%;
  overflow: scroll;
  scrollbar-width: none;
}

@media screen and (max-width: 639px) {
  #projects {
    top: calc(var(--margin) * 9.5);
    height: calc(100% - (var(--margin) * 9));
    width: calc(100% - (var(--margin) * 2));
  }
}

#projects-images {
  position: absolute;
  right: min(85vw, calc(100% - var(--margin)));
  top: min(calc(var(--margin) * 10), 33vh);
  height: calc(100%);
}

.project-extra {
  position: absolute;
  width: calc(100vw - (var(--margin) * 4));
  height: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

.img-container {
  height: max(200px, 50vh);
  width: max(50vw, 500px);
  background: no-repeat center;
  background-size: contain;
  filter: saturate(100%) contrast(100%);
  background-position: center;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: 0.8;
}

.img-container-phone {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -30%);
  height: 50%;
  width: 66%;
  background: no-repeat;
  background-size: contain;
  filter: saturate(110%) contrast(110%);
  background-position: center;
  opacity: 0.3;
}

.project {
  width: fit-content;
  margin-right: 0px;
  margin-left: auto;
  pointer-events: all;
}

.project h2 {
  border-radius: 20px;
  position: relative;
  width: fit-content;
  transition: all 0.3s;
}

.background {
  background-color: var(--background-color);
  opacity: 0.95;
  z-index: 2;
  border-radius: 10px;
}

.title {
  position: relative;
  z-index: 1;
}

.project-links {
  width: fit-content;
}

svg {
  fill: var(--primary-color);
  position: relative;
  top: 0rem;
}

#welcome {
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: var(--background-color);
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  user-select: none;
  z-index: -1 !important;
}

#name,
#website-content {
  transition: all 1s;
}

.App {
  transition: opacity 4s;
  opacity: 0;
}

.hide {
  opacity: 0 !important;
}

.appear {
  opacity: 1 !important;
}
